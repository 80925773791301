import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import InvoiceNo from './InvoiceNo';
import BillShipTo from './BillShipTo';
import InvoiceInfoTable from './InvoiceInfoTable';
import InvoiceCaseTable from './InvoiceCaseTable';
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 5,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }
  });

// Create Document Component
const InvoicePDF = ({invoice,inv_num}) => {
  if(!invoice){
    return(
      <div>Loading..</div>
    )
  }
  const date = new Date();
  const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
  const newDate = month + "-" + day + "-" + year
  const send_inv_num = [newDate, inv_num]
  return(

      <Document title={inv_num}>
          <Page size="A4" style={styles.page}>
              <InvoiceTitle title='Invoice'/>
              <InvoiceNo invoice={send_inv_num}/>
              <BillShipTo invoice={invoice[0]}/>
              <InvoiceInfoTable invoice={invoice} />
              <InvoiceCaseTable invoice={invoice} />
              <InvoiceThankYouMsg />
              {/*
              <InvoiceItemsTable invoice={invoice} />
              <InvoiceFooter /> */}
          </Page>
      </Document>
    )

}



export default InvoicePDF;
