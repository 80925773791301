import React, {useContext, useState, useEffect} from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import tableIcons from "./components/MaterialTableIcons"
import {Form, FormGroup} from 'react-bootstrap';
import Select from '@material-ui/core/Select';
import { api_url } from "./authorize";
//Pop-up Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';
import { AuthorizeContext }  from './RootContext';


 // const useStyles = makeStyles({
 //    root: {
 //      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
 //      border: 0,
 //      borderRadius: 3,
 //      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
 //      color: 'white',
 //      height: 48,
 //      padding: '0 30px',
 //    },
 //    title: {
 //      fontSize: '2em'
 //    }
 //  });
 //table Theme
 const theme = createTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });
 const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });

function APSetup() {
 const [email, setEmail] = useState([])
 const [emailName, setEmailName] = useState([])
 const [Data, setData] = useState({})
 const [rowData, setRowData] = useState({})
 const [officeData, setOfficeData] = useState([])
 const [popupData, setPopupData] = useState({})
 //Open Dialog
 const [open, setOpen] = useState(false)//add
 //Make tableStyle
 const tableClass = tableStyles() //add
 const { token, authBody, setAuthenticated, setAuthBody} = useContext(AuthorizeContext);
 
//API Calls
  // Call to populate table data
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/payables`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            setTableData(res.data)
            console.log(tableData)
          }
        }
      );

    }
    fetchData();
  }, []);



 //submit information in Pop-up Dialog
  const handleSubmit = (e) => {
    e.preventDefault()
    const { name, value } = e.target
     setPopupData({...popupData,
      [name] : value
    })
     // API call to post to the database
     axios({
      url:`https://${api_url}/api/payables`,
      method: 'post',
      data: popupData,
      headers: {
         'x-access-token' : token
      }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          window.location.reload()
        }
      }
    )
    setOpen(false)
  }
 
 // const handleSubmit = (e) => {
 //    e.preventDefault()
 //    const data =  [...tableData];}
 //setOpen(false) meanning is close Dialog
 const handleClose = (e) => {
    e.preventDefault()
    setTableData({})
    setOpen(false)
  }

  const ChangePopup = (option) => {
  const { name, value } = option.target
    setPopupData({...popupData,
      [name] : value
    })
  }

// Call to populate table data
  // useEffect(() => {
  //   fetch("https://127.0.0.1:5000/api/payables").then(
  //     res => res.json()).then(
  //     data => {
  //       setTableData(data);
  //       console.log(data);
  //     }
  //     )

  // },[]
  // );



 // Call to populate users in Dropdown 1
  useEffect(() => {
    const fetchAcct= async () => {
      const res = await axios({
        url: `https://${api_url}/api/payables?dropdown=true`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            setEmailName(res.data)
          }
        }
      );
    }
      fetchAcct();
  }, [setEmailName]);

  
// When editTherow call DB 2
  const editTheRow = (ROWdata) => {
    // API call to edit a row in the database
    const jd = {
      "job_data": ROWdata
    }
     axios({
      url:`https://${api_url}/api/payables`,
      method: 'put',
      data: ROWdata,
      headers: {
         'x-access-token' : token
      }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          console.log(res.data)
          setTableData(res.data)
          window.location.reload()
        }
      }
    )
  } 

  
  

// //submit information in Pop-up Dialog
//   const handlePost = (e) => {
//     e.preventDefault()
//     const { name, value } = e.target
//      setPopupData({...popupData,
//       [name] : value
//     })
//      // API call to post to the database
//      axios({
//       url:`https://${api_url}/api/payables`,
//       method: 'post',
//       data: popupData,
//       // headers: {
//       //    'x-access-token' : token
//       // }
//     }).then(
//       res =>{
//         if(res.data.message){
//           alert(res.data.message)
//           console.log(popupData)
//         }
//         else if(res.data.validation){
//           alert(res.data.validation.replace("_", " "))
//         }
//         else if(res.data.notLoggedIn){
//           // setAuthenticated(false)
//           // setAuthBody({role: 'visitor'})
//           alert("You have been logged out due to inactivity. Please log in again.")
//         }
//          else{
//           window.location.reload()
//           console.log(popupData)
//         }
//       }
//     )
//     setOpen(false)
//   }
  
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  //Kind of Dictionary (ex if you got "MQC" change name for show "N35"
  const onChange = (option) => {
    const { name, value } = option.target
    setTableData({...tableData,
      [name] : value
    })
  }
  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
 //tables Data --> should be move to table of DB
 const [tableData,setTableData]=useState([])

//Table looks like this
  const columns=[
    { title: 'Account #', field:'Acct_No', editable: 'never', cellStyle:{ fontSize: '1em'}},  
    { title: 'Contact Name', field:'Name', cellStyle:{ fontSize: '1em'}},
    { title: 'Email', field:'Email', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'Phone #', field:'Phone', cellStyle:{ fontSize: '1em'}},      
    { title: 'Address', field:'Address', cellStyle:{ fontSize: '1em'}},
    { title: 'MQC AMT', field:'QC_AMT', render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        ${numberWithCommas(Number.parseFloat(rowData.QC_AMT).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: 'P1 AMT', field:'P1_AMT', render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        ${numberWithCommas(Number.parseFloat(rowData.P1_AMT).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: 'P2 AMT', field:'P2_AMT', render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        ${numberWithCommas(Number.parseFloat(rowData.P2_AMT).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: 'TD AMT', field:'TD_AMT', render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        ${numberWithCommas(Number.parseFloat(rowData.TD_AMT).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: 'Term', field:'Term',
        lookup: {
                  'N30' :'N30',
                  'N45' : 'N45',
                  'N60' : 'N60',
                  'N0' : 'Due Upon',
                }
      },
    { title: 'Tax ID', field:'Tax_ID', cellStyle:{ fontSize: '1em'}}
  ]
  return(
     <div className="App">
      {/*Title*/}
      <h1 align="center">AP SETUP</h1>
      {/*Theme*/}
       <button onClick={goBack}>
      Back
     </button>
       <ThemeProvider theme={theme}>
      <MaterialTable 
      title="Accounts Payable Setup"
      icons={tableIcons}
      columns={columns} 
      data={tableData}
      editable={{
        onRowUpdate:(newRow)=>new Promise((resolve,reject)=>{
            const updatedData=[...tableData]
            editTheRow(newRow);
            setTableData(updatedData)
            console.log(newRow)
            setTimeout(()=>resolve(),500)
        }),
      }}  
      actions={[
        {         icon: tableIcons.Add,
                  tooltip: 'Add User',
                  isFreeAction: true,
                  onClick: (event) => {
                    setOpen(true)
                  }
                }
        ]}
      //Grab SelectedRows and showing up console for web page
      onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:true,
        searchFieldAlignment:"right",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,50],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"TableData",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize: 15},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }),
        }}      
       />
     </ThemeProvider>
     {/*After Click Add button(Pop-up)*/}
    <Dialog open={open} onClose={!open} aria-labelledby="form-dialog-title">
     {/*Pop-UP Title*/}
      <DialogTitle id="form-dialog-title">
        <span style={{ fontSize: '2em'}}>NEW CUSTOMER</span>
      </DialogTitle>
      <DialogContent>
        <Form className="apForm" onSubmit={handleSubmit}>
        {/*first row & box*/}
          <FormGroup>
            <div>
              <div>
                <label>Name / Email</label>
              </div>
              <div>
              <Select
                native
                defaultValue= "processor1"
                onChange={ChangePopup}
                style={{fontSize: '2rem'}}
                inputProps={{
                  name: 'Name_Email',
                  required: 'true'
                }}
                fullWidth
                required
                variant="outlined"
              >
              <option selected value={""}>Choose Name & Email</option>
                {
                  emailName ?
                  emailName.map((option) =>
                  (
                  <option

                          key={option.Email}
                          value={`${option.Name}---${option.Email}`}
                  >
                    {
                      `${option.Name}---${option.Email}`
                    }
                  </option>
                  ))
                    :
                    null
                  }
              </Select>
              </div>
            </div>
          </FormGroup>
        {/*Second row & box*/}
          <FormGroup>
            <div>
            <div>
              <label>Phone*</label>
            </div>
            <div>
            <input
              name="Phone"
              type="text"
              defaultValue={""}
              onChange={ChangePopup}
              required

            />
            </div>
            </div>
          </FormGroup>
        {/*Third row & box*/}
          <FormGroup>
            <div>
            <div>
              <label>Address</label>
            </div>
            <div>
            <input
              name="Address"
              type="text"
              onChange={ChangePopup}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>
        {/*Fourth row & box*/}          
          <FormGroup>
            <div>
            <div>
              <label>Payment Term*</label>
            </div>
            <Select
              native
              onChange={ChangePopup}
              style={{fontSize: '2rem'}}
              inputProps={{
                name: 'Term',
                required: 'true'
              }}
              fullWidth
              required
              defaultValue=""
              variant="outlined"
            >
              <option selected value={""}>Choose Term</option>
              <option value="N30">N30</option>
              <option value="N45">N45</option>
              <option value="N60">N60</option>
              <option value="N0">Due Upon</option>
            </Select>
            </div>
          </FormGroup>
        {/*Fifth row & box*/}
          <FormGroup>
            <div>
            <div>
              <label>MQC Amt</label>
            </div>
            <div>
            <input
              name="QC_AMT"
              type="number"
              onChange={ChangePopup}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>
        {/*Sixth row & box*/}          
          <FormGroup>
            <div>
            <div>
              <label>P1 AMT</label>
            </div>
            <div>
            <input
              name="P1_AMT"
              type="number"
              onChange={ChangePopup}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>
        {/*Seventh row & box*/}          
          <FormGroup>
            <div>
            <div>
              <label>P2 AMT</label>
            </div>
            <div>
              <input
                name="P2_AMT"
                type="number"
                onChange={ChangePopup}
                defaultValue={""}
                required
              />
            </div>
            </div>
          </FormGroup>
        {/*Eighth row & box*/}
          <FormGroup>
            <div>
            <div>
              <label>TD AMT</label>
            </div>
            <div>
              <input
                name="TD_AMT"
                type="number"
                onChange={ChangePopup}
                defaultValue={""}
                required
              />
            </div>
            </div>
          </FormGroup>
        {/*Ninth row & box*/}
          <FormGroup>
            <div>
            <div>
              <label>Tax ID</label>
            </div>
            <div>
              <input
                name="Tax_ID"
                type="number"
                onChange={ChangePopup}
                defaultValue={""}
                required
              />
            </div>
            </div>
          </FormGroup>

      {/*Pop-UP boxes actions*/} 
      <DialogActions
        disableSpacing
        classes={{
          root: tableClass.root,
          spacing: tableClass.spacing
        }}
      >
      {/*When Click 'Add' Button...*/}
        <button className="btn btn-success apForm" type="submit"
        variant="contained" onClick={handleSubmit} style={{fontSize: '2em'}}>
          Submit
        </button>

      {/*Close Dialog after click 'Cancel' button*/}
        <button className="btn btn-danger"
        onClick={handleClose} style={{fontSize: '2em'}}>
          Cancel
        </button>
      </DialogActions>
      </Form>
    </DialogContent>
  </Dialog>
    </div>
  );
}
export default APSetup;