import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import tableIcons from "./components/MaterialTableIcons"
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// import { Button} from 'react-bootstrap';
import ARPaymentID from './ARPaymentID'
//log in 
import { Link, useParams } from 'react-router-dom';
import {Form, FormGroup, ControlLabel, FormControl, Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
// import { Link, useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import BillDetails from './payable';
import ReceivedForm from './ReceivedForm';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { api_url } from "./authorize";
import { AuthorizeContext }  from './RootContext';

const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });
const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });
 const theme = createTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });
const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
const ARPay  = () => {
  const tableClass = tableStyles()
  const classes = useStyles();
  const { token, role, setAuthBody, setAuthenticated, authBody } = useContext(AuthorizeContext);//login
  // const [loading, setLoading] = useState(true) //login
  // // const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const [open, setOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState({})
  let [chosen, setChosen] = useState("","");
    //Open Dialog Pop-up
  const handleClickOpen = (chosen) =>() => {
      setOpen(true);
      console.log(chosen)
      setChosen(chosen);
    };
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  //setOpen(false) meanning is close Dialog
  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false)
    window.location.reload()
  }
  const onChange = (option) => {
    const { name, value } = option.target
    setTableData({...tableData,
    // setAcct({...acct,
      [name] : value
    })
  }
  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  const [tableData,setTableData]=useState([])
  
  // Call to populate table data
   useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/packing_invoice?lookup=Payment`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {

          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            setTableData(res.data)
            console.log(res.data)
          }
        }
      );

    }
    fetchData();
  }, []);
const columns= [
      { title: 'PaymentID', field:'Payment_ID', editable: "never",
      render:tableData => {
          return (
            <div>
            <Button variant="contained"
            color="primary"
            onClick={handleClickOpen(tableData)}>
            {tableData.Payment_ID}
            </Button>
            </div>
            )},
          cellStyle:{ fontSize: '1em'}, editable: 'never'},
      { title: 'Date', field:'Paid_Date', editable: "never"
        , cellStyle:{ fontSize: '1em'}
      },
      { title: 'Distributor Name', field:'Customer_Name',  editable: "never",
        cellStyle:{ fontSize: '1em'}
      },
      { title: 'Currency', field:'currency',  editable: "never",
        cellStyle:{ fontSize: '1em'}
      },
      { title: 'AMT', field:'Total', editable: "never",
        cellStyle:{ fontSize: '1em'},
        filtering: false,
        render: rowData => {
          return (
            <div style={{textAlign: 'left'}}
            >{numberWithCommas(Number.parseFloat(rowData.Total).toFixed(2))}</div>
          )
        }
      },
      { title: 'Reference#', field:'ref', editable: "never",
        cellStyle:{ fontSize: '1em'},
        filtering: false}
    ]
const [rowSelection, setRowSelection] = useState([]);

return(
    <div className="ARPayment">
      <h1 align="center">Account Receivable Payment</h1>
      <button onClick={goBack}>
      Back
      </button>
      <ThemeProvider theme={theme}>
      <MaterialTable 
      title="*Accounts Receivable Payments"
      icons={tableIcons}
      columns={columns} 
      data={tableData} 
      actions={[
        {         icon: tableIcons.Add,
                  tooltip: 'Add User',
                  isFreeAction: true,
                  onClick: (event) => {
                  history.push('./ReceivedForm');
        }
                }
        ]}
      onSelectionChange={(selectedRows)=>setSelectedRows(selectedRows)}
      // onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:true,
        searchFieldAlignment:"right",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,50],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"TableData",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize:19},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }),
        }}      
       />
     </ThemeProvider>
    {/*After Click Add button(Pop-up)*/}
    <Dialog open={open} onClose={!open} fullScreen aria-labelledby="form-dialog-title" width={900} height={300} color="primary">
      <DialogTitle id="form-dialog-title">
        {/*<span style={{ fontSize: '1em'}}>{chosen[1]}, Payment ID : {chosen[0]}</span>*/}
      </DialogTitle>
    {/*Display Payment ID with Purple Button and connecte Data from AP Fuction*/}
      <DialogContent>
          <div>
              <ARPaymentID 
                          data={chosen.Invoice_List} 
                         name={chosen.Customer_Name}
                         id={chosen.Payment_ID} 
                         sum={chosen.Total}
                         currency={chosen.currency}
              />                  
          </div>
       <DialogActions
          disableSpacing
          classes={{
            root: tableClass.root,
            spacing: tableClass.spacing
          }}
        >
        {/*Close Dialog after click 'Close' button*/}
          <button className="btn btn-primary"
          onClick={handleClose} style={{fontSize: '2em'}}>
            Close
          </button>
       </DialogActions>
      </DialogContent>
    </Dialog>
     </div>
    );
}
export default ARPay;