import React, { Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36,
        textAlign: 'right'

    },
    billTo: {
        marginTop: 20,
        paddingBottom: 10,
        fontFamily: 'Helvetica-Oblique'
    },
    leftContainer: {
      flex: 1,
      alignItems:'flex-start',
      marginRight: 75,
    },
    rightContainer: {
      flex: 1,
      width: '100%'
    },
    borderContainer: {
      paddingLeft: 10,
      paddingTop: 3,
      width: 200,
      border: '1px solid black'
    }
  });


  const BillShipTo = ({invoice}) => (
    <Fragment>
    <View style={{ flexDirection: 'row'}}>
      <View style={styles.leftContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <View style={styles.borderContainer}>
          <Text>{invoice.Billing_Address.Office_Name}</Text>
          <Text>Atten.: A/P</Text>
          <Text>{invoice.Billing_Address.Street}</Text>
          <Text>{`${invoice.Billing_Address.City}, ${invoice.Billing_Address.Country}
          ${invoice.Billing_Address.Postal_Code}`}
          </Text>
        </View>
      </View>
      <View style={styles.rightContainer}>
        <Text style={styles.billTo}>Ship To:</Text>
        <View style={styles.borderContainer}>
          <Text>{invoice.Billing_Address.Office_Name}</Text>
          <Text>Atten.: A/P</Text>
          <Text>{invoice.Billing_Address.Street}</Text>
          <Text>{`${invoice.Billing_Address.City}, ${invoice.Billing_Address.Country}
          ${invoice.Billing_Address.Postal_Code}`}
          </Text>
        </View>
      </View>
    </View>
    </Fragment>

  );

  export default BillShipTo;
