import React, { Fragment} from 'react';
import { Text, View,  StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    },
    leftContainer: {
      width: '50%'
    },
    rightContainer: {
      width: '50%'
    },
    invoiceAddressContainer: {
      flexDirection: 'row',
      marginTop: 18,
      justifyContent: 'flex-start'
    }

  });


  const InvoiceNo = ({invoice}) => (
    <Fragment>
      <View style={{flexDirection: 'row'}}>
        <View style={styles.leftContainer}>
          <View style={styles.invoiceAddressContainer}>
              <Text style={styles.label, {fontSize: 8}}>15110 Northwest FreeWay,
              Suite 150 {"\n"}
              Houston, TX 77040
              </Text>
          </View >
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.invoiceNoContainer}>
              <Text style={styles.label}>Invoice No:</Text>
              <Text style={styles.invoiceDate}>{invoice[1]}</Text>
          </View >
          <View style={styles.invoiceDateContainer}>
            <Text style={styles.label, {width: 40}}>Date: </Text>
            <Text >{invoice[0]}</Text>
          </View >
        </View>
      </View>
    </Fragment>
  );

  export default InvoiceNo;
