import React from 'react';
import { Text, View,  StyleSheet, Image } from '@react-pdf/renderer';
import logo from './../../img/Lento_Logo_2.jpeg';

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        width:1000,
        marginTop: 5,
        marginLeft: 60,
        justifyContent : 'space-between',
    },
    reportTitle:{
        // color: 'green',
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        fontWeight: 1200,
        textAlign: 'right',
        textTransform: 'uppercase',
        paddingLeft: 200,
    },
    logo: {
        width: 200,
        height: 200,
        position: 'absolute',
        top: -75,
        left: -60
    }
  });


  const PackageTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.reportTitle}>{title}</Text>

    </View>
  );

  export default PackageTitle
