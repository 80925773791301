import React, { useContext, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from 'react-router-dom';

// import {Route} from 'react-router-dom';
import { AuthorizeContext } from "../RootContext";

const AuthenticatedRoute = ({ component: Component, ...routeProps }) => {
  let { authenticated, authBody, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);

  return (

    <Route
      {...routeProps}
      render={(props) => authenticated ?
        (<Component {...props}/>
        ):

        authBody.role === 'manufacturer' ?

        <Redirect to='/packing' />
        :

        authBody.role === 'accounting' ?

        <Redirect to='/accounthome' />
        :

        <Redirect to='/' />

      }
    />
  );
};

export default AuthenticatedRoute;
