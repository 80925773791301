import React, { useState, useContext, useEffect, lazy } from "react"
import {BrowserRouter as Router, Redirect } from "react-router-dom";
import {Switch, Route} from "react-router-dom";
import ARSetup from './arsetup';
import APSetup from './apsetup'; 
import Invoice from './invoice';
import Receivable from './areceivable';
import ARPayment from './arpayment';
import EnterBill from './enterbill';
import Payable from './payable';
import Login from './Login';
import  {AuthorizeContext}  from './RootContext';
import { api_url } from "./authorize";
import { AuthorizeProvider } from './RootContext'
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { makeStyles } from '@material-ui/core/styles';
import ShippingCheck from './packing/ShippingCheck.js';
import AccountPage from './accounthome';
import NavBar from './components/NavBar';
import ReceivedForm from './ReceivedForm';
import ShelfCheck from './packing/ScanShelfCheck';
import lento_icon from './img/lento_icon.png';  
// const NavBar = lazy(() => import('./components/NavBar')) ;
// const ShippingCheck = lazy(() => import('./packing/ShippingCheck'));



 
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '26em',
    width: '15%'
  },
}));

const App = (props) => {
  let {setAuthBody, authBody, role, authenticated, setAuthenticated} = useContext(AuthorizeContext);
  // let { token, role, authenticated, authBody, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);
  const classes = useStyles();
  authBody = "";

  const [profile, setProfile] = useState(authBody || {role: "visitor"})

  const hasRole = (profile, roles) =>{
    return roles.includes(authBody.role)
  }



  return(
   <div>

    <AuthorizeProvider>
     <Router>
      { !authenticated ? null : <NavBar />}
      <Switch>
    {/*<img src={lento_icon} style={{align:'center', width:'90px'}}/>*/}
        <Route exact path="/" component={Login} />     
         <AuthenticatedRoute exact path="/arsetup" component={ARSetup} />
         <AuthenticatedRoute exact path="/apsetup" component={APSetup} />
         <AuthenticatedRoute exact path="/invoice" component={Invoice} />
         <AuthenticatedRoute exact path="/areceivable" component={Receivable} />
         <AuthenticatedRoute exact path="/arpayment" component={ARPayment} />
         <AuthenticatedRoute exact path="/enterbill" component={EnterBill} />
         <AuthenticatedRoute exact path="/payable" component={Payable} />
         <AuthenticatedRoute exact path="/accounthome" component={AccountPage} />   
         <AuthenticatedRoute exact path="/packing" component={ShippingCheck} />   
         <AuthenticatedRoute exact path="/ReceivedForm" component={ReceivedForm} />   
         <AuthenticatedRoute exact path="/shelfcheck" component={ShelfCheck} />   
      </Switch>
     </Router> 
    </AuthorizeProvider>
    </div>
  );
} ;
  
export default App;