import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceCaseFooter = ({invoice}) => {
    let amt_paid, balance_due, inv_amt;
    let ll = invoice.length
    let price = invoice[0].Unit_Price

    inv_amt = ll * price
    // balance_due = invoice.total_due
    // amt_paid = invoice.amt_paid

    const numberWithCommas = (x) => {

        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }


    return(
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}>${
              numberWithCommas(Number.parseFloat(inv_amt).toFixed(2))
            }</Text>
        </View>
    )
};

  export default InvoiceCaseFooter
