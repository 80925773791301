import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import axios from 'axios';
// import { AuthorizeContext } from "../../RootContext";
import {Form, FormGroup, ControlLabel, FormControl, Modal} from 'react-bootstrap';
import { Button} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
// import logo from './../../img/lento_logo.jpg';
// import styles from './../../styles';
import { useHistory } from 'react-router-dom';

import { api_url } from "./authorize";
// import moment from 'moment';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import tableIcons from "./components/MaterialTableIcons"
import { AuthorizeContext }  from './RootContext';

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });

  const theme = createTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

  const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });

  

const EnterBill = () => {
  const { token, role, setAuthBody, setAuthenticated, authBody } = useContext(AuthorizeContext);
  const tableClass = tableStyles()
  const [rowSelection, setRowSelection] = useState([]);
  const classes = useStyles();
  const [starting, setStarting] = useState(0)
  // const [loading, setLoading] = useState(true)
  // const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const [tableData,setTableData]=useState([])
  const [allData,setAlldata]=useState([])
  const [unpaidData,setUnpaiddata]=useState([])
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  // API CALLS
  // Call to populate table data
   useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/jobsprocessed`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {

          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            // setLoading(false)
            setTableData(res.data.unpaid_jobs)
            setStarting(res.data.starting)
            setAlldata(res.data.all_jobs)//allData
            setUnpaiddata(res.data.unpaid_jobs)
            console.log(res.data)
          }
        }
      );

    }
    fetchData();
  }, []);
            console.log(starting)

  // const hasRole = (profile, roles) =>{
  //   return roles.includes(role)
  // }


  const editTheRow = (ROWdata) => {
    // API call to edit a row in the database
    const jd = {
      "job_data": ROWdata
    }
     axios({
      url:`https://${api_url}/api/jobsprocessed?mongo_id=${ROWdata._id}`,
      method: 'put',
      data: jd,
      headers: {
         'x-access-token' : token
      }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          console.log(res.data)
          // setTableData(res.data)
          window.location.reload()
        }
      }
    )
  }
  const approve_payment = (joblist) => {
    // API call to edit a row in the database
    const lj = {
      "list_of_jobs": joblist
    }
     axios({
      url:`https://${api_url}/api/jobsprocessed?approve_payment=true`,
      method: 'put',
      data: lj,
      headers: {
         'x-access-token' : token
      }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          console.log(res.data)
          setTableData(res.data)
          window.location.reload()
        }
      }
    )
  }

  const populate_jobsprocessed = () => {
    
     axios({
      url:`https://${api_url}/api/jobsprocessed`,
      method: 'post',
      data: [],
      headers: {
         'x-access-token' : token
      }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          console.log(res.data)
          alert(res.data.message)
         
        }
      }
    )
  }

  const [state, setState] = useState({
    columns: [
    { title: 'Bill ID #', field:'billing_id', editable: 'never'},
    { title: 'Processor Name', field:'processor_name', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'Acct No', field:'Acct_No', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'Case ID #', field:'case_id', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'Shipped Date', field:'date_added', cellStyle:{ fontSize: '1em'}, editable: 'never'},      
    { title: 'Process ID', field:'process_id', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'AMT', field:'job_amt', cellStyle:{ fontSize: '1em'},type : 'currency'},
    { title: 'PMT ID #', field:'payment_id', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'PMT Date', field:'payment_date', cellStyle:{ fontSize: '1em'}, editable: 'never'}
    ]
  });

let unique = [];

const calculate_pid = (starting) => {
    // create empty list variables
    let accts = []
    let acct_ids = [];
    // grabbing the names from selected row data
    rowSelection.forEach((row) => accts.push(row.Acct_No));
    // create a set of the name to eliminate duplicates
    const unique = [...new Set(accts)];
    // combining the unique names with a unique ID
    unique.forEach((acct1,index) => acct_ids.push([acct1, starting+index]))

    console.log(acct_ids)
    // Loop thru and check the names to assign unique ID's
    acct_ids.forEach((person, index) =>{
       rowSelection.forEach((row)=> {
            if (row.Acct_No === person[0]){
               row.payment_id = person[1]
               //change the data for the db
            } 
          })
    });

}
const unpaidbillsBtn = () => {
   setTableData(unpaidData)
   console.log(unpaidData)
};

const allbillsBtn = () => {
  setTableData(allData)
   console.log(allData)
};
const approvePaymentBtn =() => {
    calculate_pid(starting);
    console.log(rowSelection)
    let rows = rowSelection;
    //if not selected any checkbox, Pop-up Alert message
    if (rows.length === 0) {
      alert('Please select at least one column.');
    } else {
    // Perform some action with the selected columns
      console.log('Selected columns:', rows);
    }
    //brig current date information and put "theDate"
    const theDate = new Date().toLocaleDateString();
    //put date in the selected rows
    rows.forEach((row) => row.payment_date = theDate);
    approve_payment(rows)
    setRowSelection([]);
    rows = []

  };

  return(
    
      <div className="EnterBill">
      <h1 align="center">ENTER BILL</h1>
      {/*When click the button go to 'approvePaymentBtn' function*/}
      <button onClick={approvePaymentBtn}>
      Approve Payment
      </button> <br />     
      <button onClick={populate_jobsprocessed}>
      Add to Enter Bill
      </button> <br />     
      
      <button onClick={allbillsBtn} style={{ margin: '.3em 0' }}>
      All Bills
      </button>
      <button onClick={unpaidbillsBtn}>
      Unpaid Billls
      </button>            
      <button onClick={goBack}>
      Back
      </button>
      <ThemeProvider theme={theme}>
      <MaterialTable 
      title="Enter Bill"
      //tableIcons from Components folder with definition
      icons={tableIcons}
      //Using above columns function
      columns={state.columns} 
      //tableData use inside of Enterbill Function but switch to real DB
      data={tableData}
      enableHiding={false}
      //update Only AMT
      editable={{
          onRowUpdate:(newRow)=>new Promise((resolve,reject)=>{           
            editTheRow(newRow)
            setTimeout(()=>resolve(),500)

        }),
      }}  
      enableRowSelection
      onSelectionChange={setRowSelection}
      options={{
        sorting:true,
        search:true,
        searchFieldAlignment:"right",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[10,20,30,40,50],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"TableData",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:true,
        showSelectAllCheckbox:true,
        showTextRowsSelected:true,
        grouping:false,
           // selectionProps: (rowData) => ({
           //   disabled: rowData.payment_id !== '',
             // color: 'primary' ,
        //   // checked: rowData.payment_id === ''
         // }),
        // isselection:(rowData) => rowData.payment_id === '',
        columnsButton:true,
        headerStyle:{background:"lightgray",fontStyle:"italic", fontSize: 15},
        // rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,

        }}      
       />
     </ThemeProvider>

     </div>
    );
}
export default EnterBill