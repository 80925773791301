export const isAllowed = (user, rules) =>
  rules.some(right => user.rules.includes(right));

export const hasRole = (user, roles) =>{
  roles.some(role => user.role.includes(role));
}

// dev
// export const api_url = "api.lentomedical.net"

// //staging
// export const api_url = "stagingapi.lentomedical.net"


//prod
// export const api_url = "prodapi.lentomedical.net"

//local
// export const api_url = "127.0.0.1:5000"
// export const api_url = "0.0.0.0:80"

//account
export const api_url = "acctapi.lentomedical.net"