import React, { forwardRef } from 'react';


const Input = forwardRef((props, ref) => {
  console.log(props)
  return (
    <div>
    <label style={{margin: '1em'}}>Scan Case: </label>
    <input type="text" {...props} ref={ref}
    />
    </div>

  )

})

export default Input;
