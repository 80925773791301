import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
    },
  });

 
const InvoiceTableRow = ({invoice}) => {
    return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.description}>{"12435"}</Text>
        <Text style={styles.qty}>{invoice.Term}</Text>
        <Text style={styles.rate}>{invoice.Due_Date}</Text>
        <Text style={styles.amount}>Fed-Ex</Text>
      </View>
    </Fragment>
    )
};

export default InvoiceTableRow
