import React from 'react';
import { Text, View,  StyleSheet, Image } from '@react-pdf/renderer';
import logo from './../img/Lento_Logo_2.jpeg';



const styles = StyleSheet.create({

    titleContainer:{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
    },
    reportTitle:{
        color: '#61dafb',
        letterSpacing: 10,
        fontSize: 25,
        marginLeft: 310,
        justifyContent: 'flex-end',
        fontWeight: 1200,
        textAlign: 'end',
        textTransform: 'uppercase',
    },
    logo: {
        width: 200,
        height: 200,
        position: 'absolute',
        top: -75,
        left: -40
    }
  });


  const InvoiceTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.reportTitle}>{title}</Text>

    </View>
  );

  export default InvoiceTitle
