import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import tableIcons from "./components/MaterialTableIcons"
import {Form, FormGroup, Button, ControlLabel, FormControl, Modal} from 'react-bootstrap';
import Select from '@material-ui/core/Select';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TablePagination  from '@material-ui/core/TablePagination';
import axios from 'axios';
import { api_url } from "./authorize";
import { AuthorizeContext }  from './RootContext';

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });
  const theme = createTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });
  const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });
  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } 
  const ReceivedForm = () => {
  const { token, role, setAuthBody, setAuthenticated, authBody , user} = useContext(AuthorizeContext);
  const classes = useStyles();
  const [data, setData] = useState({})
  const [drop, setDrop] = useState([])
  const [options, setOptions] = useState({})
  const [loading, setLoading] = useState(true)
  // const [profile, setProfile] = useState(authBody || {role: "visitor"})
  let date, acct =""
  let results, old_results, row_total = 0
  const [row_results, add_row_results] = useState(0)
  const history = useHistory();
  const [chosen, setChosen] = useState({});
  const [open, setOpen] = useState(false);
  
  const [tableData,setTableData]=useState([])
  const [formData,setFormData]=useState([])
  //  SET TABLE DATA FROM DROPDOWN LIST OF RECEIVABLES
  const SelectPayee = (chosen) => {
      let name = JSON.parse(chosen.target.value);
      const Unpaid_invoices = name.Unpaid_invoices;
      setTableData(Unpaid_invoices);
            console.log(tableData)

    };


  const submitPayment = () => {
     // API call to post to the database
     axios({
      url:`https://${api_url}/api/packing_invoice`,
      method: 'post',
      data: formData,
      headers: {
         'x-access-token' : token
      }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          window.location.reload()
          console.log(res.data)
        }
      }
    )
    setOpen(false)
  }
  const onChange = (option) => {
    const { name, value } = option.target
    setFormData({...formData,
      [name] : value
    })
  }
  const updateByIndex = (newValue, index) => {
  // setTableData(tableData => tableData.map((value, i) => i === index ? newValue: value)
    const newArray = tableData.map((v, i) => {
    if (i === index) return newValue;
    return v;
  });
  setTableData(newArray);
  }
  const handleClose = () => {
  history.push('./arpayment');
  }
  const [popupData, setPopupData] = useState({})
  const ChangePopup = (option) => {
   const { name, value } = option.target
    setPopupData({...popupData,
      [name] : value
    })
    }


useEffect(() => {
    const fetchData = async () => {
      const admin = await axios({
        url: `https://${api_url}/api/packing_invoice?lookup=AR`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {

          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setLoading(false)
            console.log(res.data)
            setDrop(res.data)
          }
        }
      );
    }
    fetchData();
  },  []);

    // COLUMNS TO DISPLACE TABLE DATA
   const columns = [
      { title: 'Invoice #', field:'Invoice_ID',  editable: "never", cellStyle:{ fontSize: '.7em'}},
      { title: 'Date', field:'Date_Issued', editable: "never", cellStyle:{ fontSize: '.7em'}},
      { title: 'Currency', field:'currency', editable: "never", cellStyle:{ fontSize: '.7em'}},
      { title: 'Invoice Amt', field:'Total_AMT', editable: "never", cellStyle:{ fontSize: '.7em'},filtering: false,
        render: rowData => {
          return (
            <div>
            {numberWithCommas(Number.parseFloat(rowData.Total_AMT).toFixed(2))}
            </div>
          )
        }
      },
      { title: 'Due Amount', field:'Current_AMT', editable: "never",cellStyle:{ fontSize: '.7em'},filtering: false,
        render: rowData => {
          return (
            <div>
            {numberWithCommas(Number.parseFloat(rowData.Current_AMT).toFixed(2))}
            </div>
          )
        }
      },
      // { title: 'Contract/PO', field:'billing_info.CPO_No', editable: "never",cellStyle:{ fontSize: '.7em'}},
      { title: 'Payment Amount', field: 'pay_amt', editable: 'always', type: 'currency', initialEditValue: 0,
        cellStyle:{ fontSize: '.7em' , width: 10}, align: 'right'}
   ]
   return (
   <>
    <Form className="arForm" >
      <FormGroup style={{ marginBottom: '5px'}, {marginLeft: '230px'} }>
           <div>
              <div>
                <label>Payee</label>
              </div>
              <div>
              <Select
                native
                defaultValue= ""
                onChange={SelectPayee}
                style={{fontSize: '2rem'}}
                inputProps={{
                  name: 'Payee',
                }}
                variant="outlined"
                required
              >
              <option selected value={[]}>Choose Payee</option>
                {
                  drop ?
                  drop.map((option) =>
                  (
                  <option

                          key={option.Acct_No}
                          // value={JSON.parse(option.Unpaid_invoices)}
                           value={JSON.stringify({
                             Unpaid_invoices: option.Unpaid_invoices,
                           })}
                  >
                    {
                      `${option.Acct_No}--${option.Customer_Name}`
                    }
                  </option>
                  ))
                    :
                    null
                  }
              </Select>
              </div>
            </div>
        </FormGroup>
          <FormGroup style={{ marginBottom: '5px'}, {marginLeft: '230px'} }>
            <div>
            <div>
              <label>Date</label>
            </div>
            <div>
            <input
              name="Date_Paid"
              type="date"
              onChange={onChange}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>
          <FormGroup style={{ marginBottom: '5px'}, {marginLeft: '230px'} }>
            <div>
            <div>
              <label>AMT</label>
            </div>
            <div>
            <input
              name="Amount"
              type="number"
              onChange={onChange}
              defaultValue={""}
              required
            />
            </div>
            </div>
          </FormGroup>   
          <FormGroup style={{ marginBottom: '5px'}, {marginLeft: '230px'} }>
            <div>
            <div>
              <label>Reference#</label>
            </div>
            <div>
            <input
              name="Reference"
              type="text"
              onChange={onChange}
              defaultValue={""}
            />
            </div>
            </div>
          </FormGroup>   
           <DialogActions style={{ marginRight: '1000px' }}>   
         <Button className="btn btn-primary arForm" 
          onClick={() =>  window.location.reload()} style={{fontSize: '2em'}}>Reset</Button>
         <button className="btn btn-danger arForm"
          onClick={handleClose} style={{fontSize: '2em'}}>
            Close
          </button>
       </DialogActions>
      {/*Pop-UP boxes actions*/}    
      </Form>
    <ThemeProvider theme={theme}>
      <div className="w-75 mx-auto mt-5" >
      <MaterialTable
        title={"Invoices"}
        columns={columns}
        data={tableData}
        icons={tableIcons}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            header: {
                actions: 'Edit'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}     
        options={{
          actionsColumnIndex: 8,
          sorting: true,
          search: false,
          showSelectAllCheckbox:true,
          showTextRowsSelected:true,
          addRowPosition:"first",
          actionsColumnIndex:-1,
          selection:false,
          search:false,
          searchFieldAlignment:"left",
          searchAutoFocus:true,
          searchFieldVariant:"outlined",
          filtering:false,
          paging:true,
          pageSizeOptions:[2,5,10,15,77],
          pageSize:5,
          paginationType:"stepped",
          showFirstLastPageButtons:false,
          paginationPosition:"bottom",
          exportButton:{
           csv: true,
          },
          exportAllData:true,
          exportFileName:"Payment_Summary",
          addRowPosition:"first",
          grouping:false,
          columnsButton:true,
          rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
          headerStyle:{background:"lightgray",fontStyle:"italic",fontSize:19},

          pageSize: 10,
          headerStyle: {
            fontSize: '.8em'
          },
          searchFieldStyle: {
            fontSize: '2rem'
          },
          exportButton: true
        }}
        style={{
          fontSize: '20px',
          width: '80%'
        }}
         editable={{
          onBulkUpdate: selectedRows =>
            new Promise((resolve, reject) => {
              console.log(selectedRows)
              let rows = Object.values(selectedRows)  
              let too_much = 'The Payment amount was greater than the Due AMT for Invoice_ID: '
              console.log(rows)
              // array will contain only the modified values
              let sub = [];
              let editedRows = [];
              // if Payment Amount(pay_amt) is larger than Due Amount(Current_AMT), not accept change rows
              rows.map(a => {
                if (a.newData.Current_AMT >= a.newData.pay_amt) {
                  editedRows.push(a);
                }
                else{
                  alert(too_much + a.newData.Invoice_ID)
                  // let too_much = alert(too_much + a.newData.Invoice_ID)
                }
              }) 
              console.log(editedRows)
              tableData.forEach(item => {
              if (!sub.find(thing => thing.Invoice_ID === item.Invoice_ID)) {
                //comparing newData.Invoice_ID with item.Invoice_ID in the rows array  
                const updatedRow = editedRows.find(sltd => sltd.newData.Invoice_ID === item.Invoice_ID);
                if (updatedRow) {
                sub.push(updatedRow.newData);
                } else {
                  item.pay_amt = 0
                //If it hasn't been modified, the original value is added instead
                sub.push(item);
               }
             }
             });
                // Here, you can use the 'sub' array to display or process only the modified values. 
                console.log(sub)
                let rr = sub.reduce((total, row) => total + row.pay_amt, 0);
                console.log(rr)
                add_row_results(rr)
                // RESET THE DISPLAY DATA
                setTableData(sub)
              // KEEP THE EXISTING DATA AND ADD TO IT, TO SEND TO BACKEND
               setFormData((formData) => ({...formData, ...{pay_updates:sub}}))
              setTimeout(() => {
                resolve();
              }, 1000);
            }),
        }}
        components={{
          Pagination: (props) =>
          (
            <div>
              <Grid container style={{padding: 15, background: '#f5f5f5'}}>
                <Grid sm={8} align="right" item><Typography variant="h6">Total</Typography></Grid>
                <Grid sm={3} style={{paddingRight: 60}}align="center" item>
                <Typography variant="h6">{} {row_results.toFixed(2)}</Typography></Grid>
                <Grid sm={1}>
                <Button
                className="btn-success"
                  disabled={parseInt(formData.Amount) !== row_results || !row_results }
                  onClick={() => 
                  submitPayment()
                  }
                  >Pay</Button>
                </Grid>
              </Grid>
              <TablePagination {...props} />
            </div>
          ),
        }}
        />
      </div>
  </ThemeProvider>
  </>
  );
};   
export default ReceivedForm;
