import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '90%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '10%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const PackageFooter = ({packg}) => {

    let packgQty = packg.length

    const numberWithCommas = (x) => {

        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }


    return(
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL QTY</Text>
            <Text style={styles.total}>{packgQty}</Text>
        </View>
    )
};

  export default PackageFooter
