import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        marginTop: 12,
        justifyContent: 'center'
    },
    reportTitle:{
        fontSize: 8,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
  });


  const InvoiceThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Make all checks payable to
        to Lento Medical Innovation, Inc.
        {"\n"}Thank you for your business!
        {"\n"}Wire Info:
        {"\n"}Bank of America, NA
        {"\n"}222 Broadway, New York, NY 10038
        {"\n"}Routing # 026009593
        {"\n"}Account # 488114076351
        {"\n"}SWIFT Code:BOFAUS3N


        </Text>
    </View>
  );

  export default InvoiceThankYouMsg
