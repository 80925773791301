import React, { memo, useContext,
  useState, useEffect, useRef
} from 'react';
import axios from 'axios';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';

import styles from './../styles';
import {Button, Modal} from 'react-bootstrap';
import { Link, useParams, Redirect, useHistory  } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { AuthorizeContext } from "./../RootContext";
import { api_url } from "./../authorize";

import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Input from './Input';
import SlipPDF from './packagePDF/slipPDF';
import InvoicePDF from '../invoice/InvoicePDF'// PDF


const useStyles = makeStyles(theme => ({
 root:{
   fontSize: "1.5em",

 },
 label:{
   fontWeight: 100,
   lineHeight: 1.2,
   letterSpacing: '0.00938em',
   fontSize: theme.typography.pxToRem(40)
 },
 sizeIcon: {
   width: 50,
   height: 50,
   color: "green"
 },
 rejectIcon: {
   width: 50,
   height: 50,
   color: "red"
 }
}));

var pdfData;

const ShelfCheck = memo((props) => {
 const { token, setAuthenticated, setAuthBody, authBody, authenticated } = useContext(AuthorizeContext);
 const classes= useStyles();
 const [data, setData] = useState([])
 const [id, setId] = useState(null)
 const [case_id, setCaseID] = useState("")
 const [loading, setLoading] = useState(false)
 const [warning, setWarning] = useState(false)
 const [colorb, setColorb] = useState("white")
 const [free, setFree] = useState(true)
 const searchInput = useRef(null);
 let pdfData;


 // useEffect(() => {
 //   handleFocus();

 // }, []);
const history = useHistory();
const goToPacking =()=>{
    history.push('/packing');
}

 function handleFocus(){
   searchInput.current.focus()
 }

 const handleKeyDown = (e) => {
   if (e.key === 'Enter') {
     const validCase = data.some(data => data.case_id === id)
     if(validCase == true){
       setData(prevState => (
         data.map((x, key) => (
           x.case_id === id ? {...x, checkIcon: true} : x
         ))
       ))
       e.target.value = ""
       setId("")
     }
     else{
       setWarning(true)
       e.target.value = ""
     }
   }
 }
 const validCase = (data) => {
   if(data.case_id === id){
     return true
   }
   else return false
 }
 const onChange = (e) => {
   setId(e.target.value)
 }
const handleClose = () => {
  setWarning(false)
  setId("")
}


 const handleSearch = (case_id) => {
   setLoading(true);
    setCaseID(case_id);
   axios({
     method: 'get',
     url: `https://${api_url}/api/shelf_check?case_id=${case_id}`,
     headers: {
       'x-access-token' : token
     }
   }).then(res =>{
     setLoading(false)
     if(res.data.message){
       alert(res.data.message)
       window.location.reload()
     }
     else if(res.data.notLoggedIn){
       setAuthenticated(false)
       setAuthBody({role: 'packager'})
       alert("You have been logged out due to inactivity. Please log in again.")
     }
     else{
       // handleFocus()
       console.log(res.data)
       setData(res.data)
     }
   })
 }

  const handleMatch = (id) => {
   setLoading(true)

   if (id === data.Acct_No){
    console.log(id)
    setColorb("lime")
    setFree(false)
   } else{
    setColorb("red")
    setFree(true)
   }
}
  const handleComplete = (id) => {

   axios({
     method: 'put',
     url: `https://${api_url}/api/shelf_check?id=${id}&case_id=${case_id}`,
     headers: {
       'x-access-token' : token
     }
   }).then(res =>{
     setLoading(false)
     if(res.data.message){
       alert(res.data.message)
       window.location.reload()
     }
     else if(res.data.notLoggedIn){
       setAuthenticated(false)
       setAuthBody({role: 'packager'})
       alert("You have been logged out due to inactivity. Please log in again.")
     }
     else{

       handleFocus();
       console.log(res.data)
       setData(res.data)
     }
   })
 }
console.log(data)
 


 //  const handleComplete = (e) => {
 //   setLoading(true)
 //   e.preventDefault()
 //   // axios({
 //   //   method: 'put',
 //   //   url: `https://${api_url}/api/packing_invoice?email=${email}`,
 //   //   headers: {
 //   //     'x-access-token' : token
 //   //   }
 //   // }).then(res =>{
 //   //   setLoading(false)
 //   //   if(res.data.message){
 //   //     alert(res.data.message)
 //   //     window.location.reload()
 //   //   }
 //   //   else if(res.data.notLoggedIn){
 //   //     setAuthenticated(false)
 //   //     setAuthBody({role: 'packager'})
 //   //     alert("You have been logged out due to inactivity. Please log in again.")
 //   //   }
 //   //   else{
 //   //     setPackgNo(res.data)
 //   //   }
 //   // })
 // }


 if (!authenticated){
   return(
     <Redirect to='/Login' />
   )
 }

 return(
   <>
   <div className="case_detail_layout" style={{backgroundColor:colorb}}>
    <div style={{display:"center"}}>
    <label style={{margin: '1em'}}>Scan Case BarCode: </label>
    <input className="barcode-label"
      name="caseID"
      value={case_id}
      // onChange={(e) => handleSearch(e.target.value)}
      onChange={(e) => setCaseID(e.target.value)}
      onKeyDown={(e) => e.key === 'Enter' ? handleSearch(case_id) : null}
      autoFocus/>

      <label style={{margin: '1em'}}>Scan SHELF BarCode: </label>
    <input className="shelf-label"
      name="ShelfID"
      value={id}
      onChange={(e) => handleMatch(e.target.value)}
      // onKeyDown={(e) => e.key === 'Enter' ? handleMatch(id) : null}
      autoFocus/>
   {/* <Input onKeyDown={handleKeyDown} onChange={onChange} id={id}
       ref={searchInput}
    />*/}
      <Button className="btn btn-lg btn-warning"
        style={{margin:"0 15%"}}
        onClick={goToPacking}
        > Packing
      </Button>
    </div>
     <div style={{margin:"0 10%"}} >
          <Button style={{width:"50%"}}
            disabled={free}
            className="btn btn-lg btn-primary"
            onClick={handleComplete}>Complete
          </Button>
        </div>
    <div style={{margin: 'auto', textAlign:"center", padding:"10em"}}>
      <h1 style={{fontSize:'10em'}}>
        {data.Acct_No}
      </h1>
    </div>
    
   
  </div>

  </>
 )

})

export default ShelfCheck;
