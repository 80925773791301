import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import 'jspdf-autotable';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import MaterialTable, { MTablePagination } from 'material-table';
import tableIcons from "./components/MaterialTableIcons"
import { AuthorizeContext }  from './RootContext';
  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });
  const theme = createTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

const columns=[
    { title: 'Reference #', field:'ref_no', cellStyle:{ fontSize: '1em'}, editable: 'never'},   
    { title: 'Date', field:'payment_date', cellStyle:{ fontSize: '1em'}, editable: 'never'},      
    { title: 'Pay Amt ', field:'payment_amt',
     render: (rowData) => (
      <div >
        {numberWithCommas(Number.parseFloat(rowData.payment_amt).toFixed(2))}
      </div>
      
    ), cellStyle:{ fontSize: '1em'}, editable: 'never'},

    { title: 'Payment ID', field:'payment_id', cellStyle:{ fontSize: '1em'}, editable: 'never'},      
    ]

const InvoiceList = (inputs) => (
  // return (
      <div className="InvoiceList">
      <h1 align="center">Invoice List</h1>
      <MaterialTable 
      title={"Invoice # : "+ inputs.inv_num}
      icons={tableIcons}
      columns={columns} 
      data={inputs.data}
      // onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:false,
        searchFieldAlignment:"left",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,77],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"Payment_Summary",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize:19},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }), 

        }}      
       />
    {<h2>Total Amount : {inputs.sum}.00-{inputs.currency}</h2>}
    </div>


  );
export default InvoiceList;