import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 30,
        fontStyle: 'bold',
        color: 'white'
    },
    description: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 8
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 5
    },
    caseid: {
      width: '25%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'left',
      paddingLeft: 5,
    },

    unitPrice: {
        width: '17.5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 4,
    },
    total: {
        width: '17.5%',
        textAlign: 'right',
        paddingRight: 4,
    },
  });

const InvoiceBlankSpace = ({rowsCount}) => {
    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map( (x, i) =>
        <View style={styles.row} key={`BR${i}`}>
          <Text style={styles.caseid}>-</Text>
          <Text style={styles.po_number}>-</Text>
          <Text style={styles.description}>-&nbsp;{'\n'}-</Text>
          <Text style={styles.qty}>-</Text>
          <Text style={styles.unitPrice}>-</Text>
          <Text style={styles.total}>-</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};

export default InvoiceBlankSpace;
