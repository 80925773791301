import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceCaseHeader from './InvoiceCaseHeader';
import InvoiceCaseRow from './InvoiceCaseRow';
import InvoiceBlankSpace from './InvoiceBlankSpace';
import InvoiceCaseFooter from './InvoiceCaseFooter';

const tableRowsCount = 20;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const InvoiceCaseTable = ({invoice}) => (

    <View style={styles.tableContainer}>
        <InvoiceCaseHeader />
        <InvoiceCaseRow cases={invoice} />
        <InvoiceBlankSpace
        rowsCount={ tableRowsCount - invoice.length}
        />
        <InvoiceCaseFooter invoice={invoice} />
    </View>

  );

  export default InvoiceCaseTable;
