import React, { memo, useState,  forwardRef, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import tableIcons from "./components/MaterialTableIcons"
import { Link } from "react-router-dom";
import { createTheme, makeStyles, ThemeProvider, alpha } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Form, FormGroup} from 'react-bootstrap';
//Pop-up Dialog(Payment ID)
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PaymentID from './PaymentID';
import { useHistory } from 'react-router-dom';
import { api_url } from "./authorize";
import axios from 'axios';
import { AuthorizeContext }  from './RootContext';
 
 //table Theme
 const theme = createTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });
 const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}
  });

const Payable = ()=>{
   const [selectedRows, setSelectedRows] = useState({})
   const { token, role, setAuthBody, setAuthenticated, authBody } = useContext(AuthorizeContext);//login
   //Open Dialog
   const [open, setOpen] = useState(false);
   //for "Payment ID AMT" to "Total AMT" insde of Pop-Up
   let [chosen, setChosen] = useState("","");
   //Make tableStyle
   const tableClass = tableStyles()
     //Data for Account Payable
  const [tableData,setTableData]=useState([])
  const [allStubs,setStubs]=useState({})
  const [paid,setPaid]=useState(false)

   //Open Dialog Pop-up
   const handleClickOpen = (chosen) =>() => {
      setOpen(true);
      setChosen(chosen);
    };
   //setOpen(false) meanning is close Dialog
   const handleClose = () => {
      setOpen(false);
      window.location.reload()
    };
   ///Kind of Dictionary 
    const onChange = (option) => {
      const { name, value } = option.target
      setTableData({...tableData,
      [name] : value
      })
    }
    const numberWithCommas = (x) => {

      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

    const history = useHistory();
    const goBack = () => {
      window.location.reload();
  };

    const flipPaid = ()=>{
        setPaid(true);
        setTableData(allStubs.paid);
        console.log(paid)
  };

    const flipUnpaid = ()=>{
        setPaid(false);
        setTableData(allStubs.unpaid)
        console.log(paid)
  };

   // Call to populate table data
   useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/paystubs`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {

          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            console.log(paid);
            setStubs(res.data);
            setTableData(res.data.unpaid)
          }
        }
      );
    }
    fetchData();
  }, []);
            // console.log(selectedRows)
            // console.log(tableData)

    const editTheRow = (newRow) => {
    
     axios({
      url:`https://${api_url}/api/paystubs`,
      method: 'put',
      data: newRow,
      headers: {
         'x-access-token' : token
      }
    }).then(
      res =>{
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
         else{
          console.log(res.data)
          alert(res.data.message)
          window.location.reload()
         
        }
      }
    )
  }
    //Data for Payment ID
    const ap = {}


   //Columens outfit
  const [state, setState] = useState({
    columns: [
    { title: 'Processor Name', field:'processor_name', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'Payment ID #', field:'payment_id', render:tableData => {
          return (
            <div>
            <Button variant="contained"
            color="primary"
            onClick={handleClickOpen(tableData)}>
            {tableData.payment_id}
            </Button>
            </div>
            )},
          cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'Payment ID AMT', field:'paystub_amt', cellStyle:{ fontSize: '1em'},type : 'currency', editable: 'never'},
    { title: 'Current Amt', field:'unpaid_amt',cellStyle:{ fontSize: '1em'},type : 'currency', editable: 'never'},      
    { title: 'Paid Amt', field:'paid_amt', cellStyle:{ fontSize: '1em'},type : 'currency'},
    { title: 'Pay Date', field:'pay_date', type:'date', cellStyle:{ fontSize: '1em'}},
    { title: 'Ref', field:'reference', cellStyle:{ fontSize: '1em'},}
    ]
  });
 return(
    <div className="payable">
      <h1 align="center">PAYABLE</h1>
      <button onClick={goBack}>
      Back
      </button>
      <button onClick={flipUnpaid}>
      UnPaid
      </button>
       <button onClick={flipPaid}>
      Paid
      </button>
      <ThemeProvider theme={theme}>
      <MaterialTable 
      title="Accounts Payable"
      icons={tableIcons}
      columns={state.columns} 
      data={tableData} 
      //update "Paid Amt", "Pay Date", "Ref"
      editable={{
        onRowUpdate:(newRow)=>new Promise((resolve,reject)=>{   
            newRow.unpaid_amt = newRow.paystub_amt - newRow.paid_amt  
            if(paid===false){      
            editTheRow(newRow)
            console.log(newRow)
            setTimeout(()=>resolve(),500)}
            else{
              alert("Paid Paystubs cannot be edited");
              window.location.reload();
            }
        }),
      }}  
      //Grab SelectedRows and showing up console for web page
      onSelectionChange={(selectedRows)=>setSelectedRows(selectedRows)}
      options={{
        sorting:true,
        search:true,
        searchFieldAlignment:"right",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,50],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"TableData",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize:19},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }),
        }}      
       />
     </ThemeProvider>
    {/*After Click Add button(Pop-up)*/}
    <Dialog open={open} onClose={!open} fullScreen aria-labelledby="form-dialog-title" width={900} height={300} color="primary">
      <DialogTitle id="form-dialog-title">
        {/*<span style={{ fontSize: '1em'}}>{chosen[1]}, Payment ID : {chosen[0]}</span>*/}
      </DialogTitle>
    {/*Display Payment ID with Purple Button and connecte Data from AP Fuction*/}
      <DialogContent>
          <div>
              <PaymentID data={chosen.jobs} 
                         name={chosen.processor_name}
                         id={chosen.payment_id} 
                         sum={chosen.paystub_amt}
              />                  
          </div>
       <DialogActions
          disableSpacing
          classes={{
            root: tableClass.root,
            spacing: tableClass.spacing
          }}
        >
        {/*Close Dialog after click 'Close' button*/}
          <button className="btn btn-primary"
          onClick={handleClose} style={{fontSize: '2em'}}>
            Close
          </button>
       </DialogActions>
      </DialogContent>
    </Dialog>
     </div>
    );
};
export default Payable