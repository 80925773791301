import React, {useState} from 'react';
import MaterialTable from 'material-table';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import tableIcons from "./components/MaterialTableIcons"
import {Form, FormGroup} from 'react-bootstrap';
import Select from '@material-ui/core/Select';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AuthorizeContext }  from './RootContext';

 const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    title: {
      fontSize: '2em'
    }
  });
 

 const theme = createTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });


  const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}

  });

  const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

const columns=[
    { title: 'Compnay Name', field:'Customer_Name', cellStyle:{ fontSize: '1em'}, editable: 'never'},   
    { title: 'Invoice #', field:'Invoice_ID', cellStyle:{ fontSize: '1em'}, editable: 'never'},      
    { title: 'Invoice Date ', field:'Date_Issued', cellStyle:{ fontSize: '1em'}, editable: 'never'},
    { title: 'Due Date', field:'Due_Date', cellStyle:{ fontSize: '1em'}, editable: 'never'},      
    { title: 'Currency', field:'currency', cellStyle:{ fontSize: '1em', textAlign:'right'}, editable: 'never'},      
    { title: 'Invoice Amount', field:'Total_AMT',  render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        {numberWithCommas(Number.parseFloat(rowData.Total_AMT).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: 'Due Amount', field:'Current_AMT',  render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        {numberWithCommas(Number.parseFloat(rowData.Current_AMT).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: 'Paid Amount', field:'',  render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        {numberWithCommas(Number.parseFloat().toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: 'Past Amount', field:'pay_amt',  render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        {numberWithCommas(Number.parseFloat(rowData.pay_amt).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
    { title: '[Total Amount]', field:'Total_Due_Sum',  render: (rowData) => (
      <div style={{ textAlign: 'right' }}>
        {numberWithCommas(Number.parseFloat(rowData.Total_Due_Sum).toFixed(2))}
      </div>
      
    ),
    cellStyle: { fontSize: '1em' }
  },
  //   { title: 'Total Amount',
  //   field: 'pay_total',
  //   align: 'right',
  //   editable: 'never',
  //   render: (rowData) => (
  //     <div style={{ textAlign: 'right' }}>
  //       {numberWithCommas(Number.parseFloat(rowData.pay_total).toFixed(2))}
  //     </div>
      
  //   ),
  //   cellStyle: { fontSize: '1em' }
  // },
    
  ]
const ARID = (inputs) => (
  // return (
      <div className="ARPaymentID">
      <h1 align="center">Invoice Detail(Receivable)</h1>
      <MaterialTable 
      title={"Company Name : "+ inputs.name}
      icons={tableIcons}
      columns={columns} 
      data={inputs.data}
      // onSelectionChange={(selectedRows)=>console.log(selectedRows)}
      options={{
        sorting:true,
        search:false,
        searchFieldAlignment:"left",
        searchAutoFocus:true,
        searchFieldVariant:"outlined",
        filtering:false,
        paging:true,
        pageSizeOptions:[2,5,10,15,77],
        pageSize:5,
        paginationType:"stepped",
        showFirstLastPageButtons:false,
        paginationPosition:"bottom",
        exportButton:{
           csv: true,
        },
        exportAllData:true,
        exportFileName:"Payment_Summary",
        addRowPosition:"first",
        actionsColumnIndex:-1,
        selection:false,
        showSelectAllCheckbox:false,
        showTextRowsSelected:false,
        grouping:false,
        columnsButton:true,
        rowStyle:(data,index)=>index%2==0?{background:"#f5f5f5"}:null,
        headerStyle:{background:"lightgray",fontStyle:"italic",fontSize:19},
        selectionProps:rowData=>({
            disabled:rowData.tax==null,
            color:"primary"
            }), 

        }}      
       />
    <h2>Total Amount : {inputs.sum}-{inputs.currency}</h2>
    </div>


  );

export default ARID;
